import React from 'react'
import PropTypes from 'prop-types'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      />
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Projects</h2>
          <a href="https://bakalist.app">Bakalist</a>
          <p>
            A shared shopping list PWA written in Rails and NextJS.
          </p>
          <a href="https://www.kylepoole.me/quiz/">The great wikipedia quiz</a>
          <p>
            A multiplayer quiz game in which all questions and answers are pulled from Wikipedia and categorized using natural language processing. Written in Vue.js and Python.
          </p>
          <a href="https://github.com/yokelpole/panel_attack">Panel Attack</a>
          <p>
            Panel attack is a clone of Panel De Pon/Tetris Attack written in
            PhaserJS.
          </p>
          <a href="https://github.com/yokelpole/MinerSDL">MinerSDL</a>
          <p>
            MinerSDL is a remake of Harrel W. Stiles' MinerVGA written in C++
            SDL.
          </p>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
